<template>
    <div class="con">
        <div class="container" :style="{left:selectSide==='left'?'75px':'0',right:selectSide==='right'?'75px':'0'}">
            <!-- 头部标题 -->
            <div class="banner">
                <div >
                    <div style="height:30px;"></div>
                    <div class="title">选择角色</div>
                    <div class="desc">请仔细阅读角色条件，一经选择不可自行修改，如需切换需联系客服</div>
                </div>
            </div>

            <div class="select">
                <!-- 每个框 -->
                <div class="box" @click="pickRole(1)">
                    <p style="height:40px;"></p>
                    <div class="title">
                        自由打印
                    </div>
                    <div class="word">
                        <p>
                            <span class="circle"></span><span>选择本项后将不能使用后面两项功能</span>
                        </p>
                        <p>
                            <span class="circle"></span><span>选择本项后将不能使用后面两项功能</span>
                        </p>
                    </div>
                </div>

                <div class="box" @click="pickRole(2)">
                    <p style="height:40px;"></p>
                    <div class="title">
                        电商打印
                    </div>
                    <div class="word">
                        <p> <span class="circle"></span><span>需要拥有电商店铺，并在电商平台订</span></p>
                        <p><span class="circle hide"></span><span>购本系统，方可匹配</span></p>

                        <p> <span class="circle"></span><span>选择本项身份同时拥有电商订单和自</span></p>
                        <p><span class="circle hide"></span><span>由打印功能</span></p>
                    </div>
                </div>

                <div class="box" @click="pickRole(3)">
                    <p style="height:40px;"></p>
                    <div class="title" style="line-height:150%;">
                        进销存管理综合系统
                    </div>
                    <div class="word">
                        <p>
                            <span class="circle"></span>
                            <span>除自身外将同时拥有前二者身份功能</span>
                        </p>
                        <p><span class="circle"></span><span>因为本项是进销存系统，前期使用</span></p>
                        <p><span class="circle hide"></span><span>将会比较繁琐需要用户制作所有客户</span></p>
                        <p><span class="circle hide"></span><span>资料和商品数据</span></p>
                        <p>
                            <span class="circle"></span>
                            <span>后续完善后系统会相当强大，能帮助</span>
                        </p>
                        <p><span class="circle hide"></span><span>用户准确管理店铺的经营数据，并提</span></p>
                        <p><span class="circle hide"></span><span>供数据分析等功能</span></p>

                        <p>
                            <span class="circle"></span>
                            <span>选择后不能切换回简单版本</span>
                        </p>


                    </div>
                </div>


            </div>
        </div>
        <alikey></alikey>
    </div>
</template>

<script>
    import {setRole,login} from "../common/js/api";
    import tools from "../../extends/tools.js";
    import Alikey from '../../components/common/alikey.vue';

    // import ls from "local-storage";
    
    export default {
        name: "HomeHospital",
        data() {
            return {
                uid:this.$route.query.id,
                lock:true,
                role_id:0
            }
        },
        components:{
            Alikey
        },
        methods: {
            async pickRole(role_id){
                if(!this.lock){
                    return;
                }
                this.role_id = role_id;
                this.lock = false;

                let word = '一旦选择，后续修改需要联系客服,是否确认选择该角色?';
                if(role_id == 3){
                    word = '选择角色三将无法再次修改角色,是否确认选择该角色?'
                }
                this.$confirm(word,'温馨提示',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'                    
                })
                .then(()=>{
                    //确认
                    this.setRole(role_id);
                })
                .catch(()=>{
                    // 取消
                    this.$message({
                        type:'error',
                        message:'您取消了操作'
                    });

                    this.lock = true;
                })
               
            },
            login(){
                login({
                    account:localStorage.getItem('account'),
                    password:tools.encrypt(localStorage.getItem('password'))
                }).then(res => {
                    if(res.code==200){
                        //1.本地保存用户信息
                        localStorage.setItem('uid',res.data.info.id);
                        localStorage.setItem('isReg',false);
                        localStorage.setItem('menu',JSON.stringify(res.data.auth));
                        localStorage.setItem('userinfo',JSON.stringify(res.data.info));
                        localStorage.setItem('authorization-bearer',res.data['authorization-bearer']);
                        // 供前端路由访问
                        localStorage.setItem('loginStatus',1);

                        this.jumpHome();
                    }else {
                        this.$message({
                            type: "error",
                            message: res.msg,
                        });
                    }
                    this.lock = true;
                }). catch(err => {
                    console.log(err);
                    this.$message({
                        type: "error",
                        message: '网络不畅通，请稍候重试2！',
                    });
                    this.lock = true;
                })
            },
            jumpHome(){
                let path = '';
                if(this.role_id == 1){
                    path = '/print';
                }
                if(this.role_id == 2){
                    path = '/order/month3';
                    // this.$alert('跳转电商首页,未开放');
                }
                if(this.role_id == 3){
                    path = '/data_analysis/all';
                    // this.$alert('跳转ERP首页,未开放');
                }
                
                this.$router.push({
                    path:path
                })

                this.lock = true;
            },
            setRole(role_id){
                setRole({
                // **0day** 此处有任意用户修改漏洞，服务端的锅
                   id:this.uid,
                   role_id:role_id
                }).then((res)=>{
                   if(res.code == 200){
                        this.$message({
                            type: "success",
                            message:'选择角色成功！',
                        });
                        //注册用户静默登录
                        // console.log(localStorage.getItem('isReg'));
                        // return;
                        if(localStorage.getItem('isReg')){
                            this.login();
                        }else{
                            //已登录用户直接跳转对应的角色首页
                            this.jumpHome();
                        }
                   }else{
                        this.$message({
                            type: "success",
                            message:'操作失败，请联系系统客服！',
                        });
                        this.lock = true;
                   }
                   
               }).catch((error)=>{
                   console.log(error);
                    this.$message({
                        type: "error",
                        message:'网络不顺畅，请稍候再试',
                    });

                    this.lock = true;
               })

            }
        },
        created(){

        }
    }
</script>

<style scoped lang="less">
    .con{
        width: 100%;
        height: 940px;
        background: url("~@/assets/img/home/bg.jpg") top center no-repeat;
        /*background-size: 100% 100%;*/
        background-size: cover;
        background-position: center;
        .div_container{
            width: 600px;
            height:700px;
            margin:50px 0;
        }
    }
    .container{
        height:940px;min-width:1280px;max-width:1400px;margin:0 auto;
        background:rgba(255, 255, 255, 0);
        .banner{
            height:160px;color:#fff;padding-left:50px;
            .title{
                font-size: 35px;
                font-weight: 600;
                color:#006eff;
            }
            .desc{
                color:#f00;
                margin-top:10px;
            }
        }
        .select{
            width:100%;height:600px;margin:0 auto;text-align:center;font-family:"Microsoft Yahei";
            .box{
                width:300px;height:380px;background:#fff;border:5px solid #fff;cursor: pointer;
                margin:0 0 0 30px;display:inline-block;position:relative;
                .title{
                    width:220px;position:absolute;left:40px;top:30px;
                    font-size:30px;text-align: center;background:#EBEBEB;
                    height:95px;line-height: 95px;
                    color:#006eff;
                }
                .word{
                    width:220px;text-align:left;position:absolute;left:40px;top:145px;
                    p{
                        height:18px;color:#777;margin-top:4px;line-height:18px;font-family:"Microsoft Yahei";
                        .circle{
                            display: inline-block;width:10px;height:10px;background:#EBEBEB;border-radius: 50%;
                            margin-right:10px;
                        }
                        .circle.hide{
                            opacity: 0;
                        }
                    }

                }
            }
            .box:hover{
                border:5px solid #00a5c1;
            }
            
        }
    }


</style>